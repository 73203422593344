import { useState, useRef, useCallback } from 'react';
import Cookies from 'js-cookie';
import mainApi from '../../api/MainApi';
import { handleMainError } from '../errors/errorHandlers';
const useFetchPlaylist = () => {
  const [devicePlaylist, setDevicePlaylist] = useState(null);
  const lastUpdateAtRef = useRef(null);
  let counts = {};

  function countLog(message) {
    if (!counts[message]) {
      counts[message] = 1;
    } else {
      counts[message]++;
    }
    console.log(` (${counts[message]}) ${message}`);
  }
  const fetchPlaylistByDeviceId = useCallback(async () => {
    try {
      const id = Cookies.get('device_id');
      const response = await mainApi.getPlaylistByDeviceId(id);

      if (response.unixTimestamp !== lastUpdateAtRef.current) {
        countLog('stage 1: Playlist has updated:', response);
        setDevicePlaylist(response);
        lastUpdateAtRef.current = response.unixTimestamp;
      }
    } catch (error) {
      if (error.name === 'PLAYLIST_NOT_FOUND') {
        console.warn('Playlist not found');
        setDevicePlaylist(null);
        return;
      }
      if (error.status === 404) {
        Cookies.remove('device_id');
        window.location.reload();
      }
      if (error.status === 405) {
        // 405 - Method Not Allowed
        setDevicePlaylist(null);
        return;
      }
      handleMainError(error, true);
    }
  }, []);

  return { devicePlaylist, fetchPlaylistByDeviceId };
};

export default useFetchPlaylist;
