import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import {
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
  VideoSettings as VideoSettingsIcon,
  Bookmark as BookmarkIcon,
  BookmarkBorder as BookmarkBorderIcon,
  CheckCircle as CheckCircleIcon,
  Place as PlaceIcon,
  Update as UpdateIcon,
  Computer as ComputerIcon,
} from "@mui/icons-material";
import { Play } from "lucide-react";
import tv from "../../../assets/products/tv-background-devices.webp";
import "./DeviceItem.css";

const DeviceItem = ({
  data,
  onDeleteDevice,
  onClick,
  onBookmarkDevice,
  deviceType,
  onShowStreamClick,
  onVerifyDevice,
  id, 
  onSelectedItem,
  onSelectDevicePlaylist,
}) => {
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [isDeviceModalOpen, setIsDeviceModalOpen] = useState(false);

  if (!data || !deviceType) return null;

  const {
    _id,
    title,
    place,
    isStreaming,
    isBookmarked,
    approvedBy,
    updatedAt,
    userAgent,
  } = data;

  const handleAction = (action, event) => {
    event.stopPropagation();
    action();
  };

  const formatDateTime = (inputDateTime) => {
    const date = new Date(inputDateTime);
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    };
    return new Intl.DateTimeFormat("en-GB", options)
      .format(date)
      .replace(",", "\n");
  };


 

 

  const actionButtons = [
 
    {
      icon: <DeleteIcon style={{ fontSize: "26px" }} />,
      label: "מחק",
      action: () => onDeleteDevice(_id),
      show:
        deviceType === "pending" ||
        deviceType === "saved" ||
        deviceType === "verified",
    },

    {
      icon: <CheckCircleIcon style={{ fontSize: "26px" }} />,
      label: "אמת",
      action: () => onVerifyDevice(_id),
      show: deviceType === "pending",
    },
    {
      icon: <CheckCircleIcon style={{ fontSize: "26px" }} />,
      label: "בחר",
      action: () => onSelectedItem(_id),
      show: deviceType === "select",
    },
    /*     {
      icon: <VisibilityIcon />,
      label: 'תצוגה מקדימה',
      action: () => onShowStreamClick(data),
      show: deviceType !== 'pending' && isStreaming,
    }, */
    {
      icon: isBookmarked ? (
        <BookmarkIcon style={{ fontSize: "26px" }} color="secondary" />
      ) : (
        <BookmarkBorderIcon style={{ fontSize: "26px" }} />
      ),
      label: isBookmarked ? "הסר מהמועדפים" : "שמור במועדפים",
      action: () => onBookmarkDevice(_id),
      show: deviceType === "saved" || deviceType === "verified",
    },
    {
      icon: <Play style={{ fontSize: "26px" }} />,
      label: "הצמד",
      action: () => onSelectDevicePlaylist(_id),
      show: deviceType === "saved" || deviceType === "verified",
    },
  ];

  const renderActionButtons = () => (
    <div className="device-actions">
      {actionButtons.map(
        (button, index) =>
          button.show && (
            <Tooltip key={index} title={button.label}>
              {button.component ? (
                <button.component {...button.props}>
                  <IconButton>{button.icon}</IconButton>
                </button.component>
              ) : (
                <IconButton
                  onClick={(e) => handleAction(button.action, e)}
                  aria-label={button.label.toLowerCase()}
                >
                  {button.icon}
                </IconButton>
              )}
            </Tooltip>
          )
      )}
    </div>
  );

  const renderInfoItem = (value, label, icon) =>
    value && (
      <div className="device-info__text-wrapper">
        <span
          className={`device__text device_text-type_${label.toLowerCase()}`}
        >
          {value}
          {icon}
        </span>
        {/*         <span
          className={`device__text device_text-type_${label.toLowerCase()}`}
        >
          {label}
        </span> */}
      </div>
    );

  return (
    <li className="device-item" data-device-id={id} onClick={() => onClick(data)}>
      <div className="flex flex-col items-start p-4 bg-gray-100 rounded-t-lg device-item-header">
        <h4 className="text-sm font-semibold text-gray-600 mb-1">
          {_id.slice(-6).toUpperCase()}
        </h4>
        <span className="text-lg font-bold text-gray-800">
          {title || "ללא שם"}
        </span>
      </div>
      <img className="device__image" src={tv} alt="device" />
      <div className="device-info">
        {renderActionButtons()}
        {renderInfoItem(
          approvedBy,
          "מאשר",
          <CheckCircleIcon fontSize="small" />
        )}

        {renderInfoItem(place, "מיקום", <PlaceIcon fontSize="small" />)}
        {renderInfoItem(
          formatDateTime(updatedAt),
          "עדכון אחרון",
          <UpdateIcon fontSize="small" />
        )}
        {renderInfoItem(userAgent, "סוכן", <ComputerIcon fontSize="small" />)}
      </div>
    </li>
  );
};

export default DeviceItem;
