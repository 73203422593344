/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import "./AddNewPlaylist.css";
import PlaylistForm from "../../components/PlaylistForm/PlaylistForm";
import mainApi from "../../api/MainApi";
function AddNewPlaylist({ onSubmit }) {
  return (
    <div className="device-add-view">
      <PlaylistForm onSubmit={onSubmit} />
    </div>
  );
}

export default AddNewPlaylist;
