import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Calendar, Eye, Trash2, User, Check } from "lucide-react";
import PriceSwitcher from "../PriceSwitcher/PriceSwitcher";
import "./RecentItemCard.css";
import MediaCarousel from "../MediaCarousel/MediaCarousel";

const RecentItemCard = ({
  item,
  type,
  onDelete,
  cardType,
  onSelect,
  className,
  imgClassName,
  isLoading, // 🔹 נוסף כדי להציג טעינה בעת מחיקה
}) => {
  const formattedDate = new Date(item.createdAt).toLocaleDateString("he-IL", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const handleSelectItem = () => {
    onSelect(item);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete(item._id, type);
  };

  useEffect(() => {
    console.log(type);
  }, [cardType]);

  return (
    <div
      className={`bg-white shadow rounded-lg p-4 w-full max-w-sm hover:shadow-lg transition-shadow duration-300 relative group recent-item-card ${className}`}
    >
      {/* 🔹 אזור העליון - שם המשתמש והפעולות */}
      <div
        className="flex items-center justify-between p-2 bg-gray-50 rounded-t-lg shadow-sm resct-item1"
        style={{ direction: "rtl" }}
      >
        <User size={24} className="text-gray-500 " />
        <span className="text-lg font-medium text-gray-700 truncate max-w-[200px]">
          {item?.uploadedBy || "Unknown User"}
        </span>
        {cardType === "select" && (
          <button
            onClick={handleSelectItem}
            className="p-1.5 rounded-full hover:text-green-500 hover:bg-green-100 transition-colors duration-200"
            aria-label="Select item"
          >
            <Check size={20} />
          </button>
        )}
        {cardType === "delete" && (
          <button
            onClick={handleDelete}
            disabled={isLoading} // 🔹 הכפתור מנוטרל בזמן מחיקה
            className={`p-1.5 rounded-full ${
              isLoading
                ? "bg-gray-300 cursor-not-allowed"
                : "hover:text-red-500 hover:bg-red-100"
            } transition-colors duration-200`}
            aria-label="Delete item"
          >
            {isLoading ? "מוחק..." : <Trash2 size={20} />}
          </button>
        )}
      </div>

      {/* 🔹 כותרת הפריט */}
      {item.title && (
        <div className="mb-4">
          <h3
            className="font-semibold text-lg  resct-item12"
            title={item.title}
          >
            {item.title}
          </h3>
        </div>
      )}

      {/* 🔹 תיאור הפריט */}
      {item.description && (
        <div className="mb-4">
          {type.toLowerCase() !== "asset" && (
            <p className="text-sm text-gray-600 mb-4 line-clamp-2">
              {item.description}
            </p>
          )}
        </div>
      )}

      {/* 🔹 תצוגה של תמונה/וידאו בהתאם לסוג הפריט */}
      <div
        style={{
          direction: "rtl",
          textAlign: "center",
          gap: "10px",
          flexDirection: "column",
        }}
        className="flex justify-between items-center"
      >
        {type.toLowerCase() === "asset" && item.mediaType === "image" && (
          <img
            src={item.mediaUrl}
            alt={item.title}
            className={`recent-item-card__image ${imgClassName}`}
          />
        )}

        {type.toLowerCase() === "asset" && item.mediaType === "video" && (
          <video controls className="recent-item-card__image">
            <source src={item.mediaUrl} type={`video/${item.extension}`} />
            הדפדפן לא תומך בוידאו
          </video>
        )}

        {/* 🔹 תצוגה מיוחדת לתבניות */}
        {type.toUpperCase() === "TEMPLATE" && (
          <div className={`template`}>
            <MediaCarousel item={item} timer={3000} />
          </div>
        )}

        {/* 🔹 קישור לצפייה בפרטים */}
        {cardType !== "select" && (
          <Link
            to={`/c/resources/${type}s/${item._id}`}
            className="inline-flex items-center text-blue-500 hover:text-blue-700"
            title="צפה בפרטים"
          >
            <Eye className="ml-2 h-6 w-6" />
            <span>צפה בפרטים</span>
          </Link>
        )}

        {/* 🔹 תאריך העלאה */}
        <div
          className="flex items-center flex- text-sm text-gray-500"
          title={formattedDate}
        >
          <Calendar className="ml-1 h-6 w-6" />
          <span>{formattedDate}</span>
        </div>
      </div>
    </div>
  );
};

export default RecentItemCard;
