/*eslint-disable*/
import React, { useState } from "react";
import DeviceItem from "../DeviceItem/DeviceItem";
import "./DeviceList.css";
import { useEffect } from "react";
import Preloader from "../../Preloader/Preloader";
import PagePreloader from "../../PagePreloader/PagePreloader";
const DeviceList = ({
  onDeleteDevice,
  onItemClick,
  onBookmarkDevice,
  onItemSettings,
  deviceType,
  deviceList,
  onShowStreamClick,
  onVerifyDevice,
  onSelectedItem,
  onSelectDevicePlaylist,
}) => {
  useEffect(() => {
    console.log("DeviceList rendered", deviceList);
  }, [deviceList]);
  return (
    <div className="device-list">
  
      <ul className="device-list-ul">
        {deviceList?.map((device) => (
          <DeviceItem
          onSelectDevicePlaylist={onSelectDevicePlaylist}
            key={device._id}
            id={device._id}
            data={device}
            deviceType={deviceType}
            onDeleteDevice={onDeleteDevice}
            onSettings={onItemSettings}
            onBookmarkDevice={onBookmarkDevice}
            onClick={onItemClick}
            onVerifyDevice={onVerifyDevice}
            onShowStreamClick={onShowStreamClick}
            onSelectedItem={onSelectedItem}
          />
        ))}
      </ul>
    </div>
  );
};

export default DeviceList;
