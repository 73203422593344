import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "../src/components/App/App";
import { BrowserRouter as Router } from "react-router-dom";
import { FetchProvider } from "../src/contexts/FetchContext";
import { AuthStatusProvider } from "../src/contexts/AuthStatus";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  /*   <React.StrictMode>  */
  <QueryClientProvider client={queryClient}>
    <AuthStatusProvider>
      <Router>
        <FetchProvider>
          <App />
        </FetchProvider>
      </Router>
    </AuthStatusProvider>
  </QueryClientProvider>
  /*   </React.StrictMode> */
);
