// src/utils/toast.js

import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Displays a toast notification with customizable message and type.
 * 
 * @param {string} message - The message to display in the toast.
 * @param {'success' | 'error' | 'info' | 'warning'} [type='success'] - The type of toast notification.
 *        - 'success' (default) - Green success message.
 *        - 'error' - Red error message.
 *        - 'info' - Blue informational message.
 *        - 'warning' - Yellow warning message.
 * 
 * @example
 * // Show a success toast
 * showToast("Operation completed successfully!", "success");
 * 
 * // Show an error toast
 * showToast("An error occurred!", "error");
 * 
 * // Show an informational toast
 * showToast("This is some useful info.", "info");
 * 
 * // Show a warning toast
 * showToast("Be careful with this action!", "warning");
 */
const showToast = (message, type = 'success') => {
  // Dismiss all existing toasts before showing a new one
  toast.dismiss();

  const toastOptions = {
    position: 'bottom-left', // Position of the toast notification
    autoClose: 3500, // Automatically close after 3.5 seconds
    hideProgressBar: false, // Show progress bar
    closeOnClick: true, // Allow closing toast by clicking on it
    pauseOnHover: true, // Pause auto-close when hovered
    draggable: true, // Allow dragging the toast
    progress: undefined, // Default progress indicator behavior
    theme: 'dark', // Use dark theme for the toast
    transition: Slide, // Apply slide transition effect
    rtl: true, // Set right-to-left text direction
  };

  // Display the toast based on the type
  switch (type) {
    case 'success':
      toast.success(message, toastOptions);
      break;
    case 'error':
      toast.error(message, toastOptions);
      break;
    case 'info':
      toast.info(message, toastOptions);
      break;
    case 'warning':
      toast.warning(message, toastOptions);
      break;
    default:
      toast(message, toastOptions);
  }
};

export default showToast;
