import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  TextField,
  Button,
  createTheme,
  ThemeProvider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Cached, DeleteForever } from '@mui/icons-material';
import { IMPORT, prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import background1 from '../../assets/TUMBLER/background1.webp';
import background2 from '../../assets/TUMBLER/background2.webp';
import { Trash2 } from 'lucide-react';
import './TemplateMaker.css';
import videoBackground1 from '../../assets/TUMBLER/videoBackground1.webp';
import videoBackground2 from '../../assets/TUMBLER/videoBackground2.webp';
import { SendHorizontal } from 'lucide-react';

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

// Create a theme with RTL direction
const rtlTheme = createTheme({
  direction: 'rtl',
});

/* const ThumbnailDropdown = ({ options, onOptionSelected, value }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="template-select-label" className="">
        בחר תבנית
      </InputLabel>
      <Select
        labelId="template-select-label"
        id="template-select"
        value={value.value}
        style={{ direction: 'ltr' }} // = rtl
        label="בחר תבנית"
        onChange={(e) =>
          onOptionSelected(options.find((opt) => opt.value === e.target.value))
        }
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <div className="flex items-center justify-end w-full">
              <span className="mr-2">{option.label}</span>
              <img
                src={option.imgSrc}
                alt={option.label}
                className="w-16 h-12 ml-3 object-cover"
              />
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}; */

const TemplateMaker = ({ onSubmit, onPayngoItemFetch, data }) => {
  const [formState, setFormState] = useState({
    barcode: '349592',
    title: '',
    oldPrice: '',
    newPrice: '',
    productMediaUrls: [],
    text: '',
  });
  const [barcodeData, setBarcodeData] = useState(null);
  const [barcodeError, setBarcodeError] = useState(false);
  const [barcodeErrorText, setBarcodeErrorText] = useState('');
  const [isBarcodeLoading, setIsBarcodeLoading] = useState(false);
  const { deviceId } = useParams();

  const options = [
    { value: '1', label: 'תבנית 1', imgSrc: background1, isVideo: false },
    { value: '2', label: 'תבנית 2', imgSrc: background2, isVideo: false },
    { value: '3', label: 'תבנית 3', imgSrc: videoBackground1, isVideo: true },
    { value: '4', label: 'תבנית 4', imgSrc: videoBackground2, isVideo: true },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleInputChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };
  const restForm = () => {
    setFormState({
      barcode: '349592',
      title: '',
      oldPrice: '',
      newPrice: '',
      productMediaUrls: [],
      text: '',
    });

    setBarcodeError(false);
    setIsBarcodeLoading(false);
    setSelectedOption(options[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      ...formState,
      templateId: selectedOption.value,
      deviceId: deviceId,
      type: selectedOption.isVideo ? 'video' : 'template',
    });
    restForm();
  };

  const handleBarcodeSubmit = async () => {
    const { barcode } = formState;

    // Reset states
    setBarcodeErrorText('');
    setBarcodeError(false);
    setBarcodeData(null);
    setIsBarcodeLoading(true);

    try {
      if (!barcode || barcode.length < 6) {
        throw new Error('אנא הזן 6 ספרות');
      }
      const response = await onPayngoItemFetch(barcode);
      console.log(response);

      if (!response) {
        throw new Error('שגיאה בקבלת תשובה מהשרת. אנא נסה שוב.');
      }
      if (response.message === 'NotFound') {
        throw new Error('מוצר לא נמצא במערכת');
      }

      setBarcodeData(response);
    } catch ({ message }) {
      setBarcodeErrorText(message);
      setBarcodeError(true);
    } finally {
      setIsBarcodeLoading(false);
    }
  };

  const handleDeleteImage = (index) => {
    setFormState({
      ...formState,
      productMediaUrls: formState.productMediaUrls.filter(
        (_, idx) => idx !== index
      ),
    });
  };

  useEffect(() => {
    if (barcodeData) {
      setIsBarcodeLoading(false);
      setFormState({
        ...formState,
        title: barcodeData.title,
        oldPrice: barcodeData.price,
        text: barcodeData.overview,
        productMediaUrls: barcodeData.productMediaUrls,
      });
    }
  }, [barcodeData]);

  useEffect(() => {
    if (formState?.barcode?.length === 0) {
      setBarcodeError(false);
      setBarcodeErrorText('');
    }
  }, [formState?.barcode]);
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={rtlTheme}>
        <motion.div
          className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          dir="rtl"
        >
          <motion.div
            className="mb-6 text-center"
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.2 }}
          >
            <TextField
              fullWidth
              name="templateName"
              label="הכנס שם תבנית"
              multiline
              value={formState.templateName}
              onChange={handleInputChange}
            />
            {/*      <h2 className="text-2xl font-bold text-gray-800">
              {data?.title || 'ג'}
            </h2> */}
            {/*   <p className="text-sm text-gray-600">
              מזהה: {data?.id}, פעיל: {data?.isActive ? 'כן' : 'לא'}
            </p> */}
          </motion.div>

          <form onSubmit={handleSubmit} className="space-y-6">
      {/*       <ThumbnailDropdown
              options={options}
              onOptionSelected={setSelectedOption}
              value={selectedOption}
            /> */}

            <motion.div
              className="flex items-center space-x-2 space-x-reverse w-full"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <TextField
                fullWidth
                name="barcode"
                label="אתר לפי מק''ט"
                type="number"
                value={formState.barcode || ''}
                onChange={handleInputChange || ''}
                error={barcodeError}
                InputProps={{
                  inputProps: {
                    min: 0,
                    style: { textAlign: 'center' },
                  },
                }}
              />
              <Button
                onClick={handleBarcodeSubmit}
                variant="contained"
                color="primary"
                disabled={isBarcodeLoading}
                style={{ height: '55px' }}
              >
                <Cached className={isBarcodeLoading ? 'animate-spin' : ''} />
              </Button>
            </motion.div>
            {barcodeErrorText && (
              <span className="text-red-500 text-sm mt-5">
                {barcodeErrorText}
              </span>
            )}
            <TextField
              fullWidth
              name="title"
              label="הכנס כותרת"
              multiline
              value={formState.title}
              onChange={handleInputChange}
            />

            <div className="grid grid-cols-2 gap-4">
              <TextField
                fullWidth
                name="oldPrice"
                label="מחיר קודם"
                type="number"
                value={formState.oldPrice}
                onChange={handleInputChange}
                InputProps={{ inputProps: { min: 0 } }}
              />
              <TextField
                fullWidth
                name="newPrice"
                label="מחיר נוכחי"
                type="number"
                value={formState.newPrice}
                onChange={handleInputChange}
                InputProps={{ inputProps: { min: 0 } }}
              />
            </div>

            <TextField
              fullWidth
              name="text"
              label="פירוט על המוצר"
              multiline
              rows={4}
              value={formState.text || ''}
              onChange={handleInputChange}
            />

            {formState?.productMediaUrls?.length > 0 && (
              <motion.div
                className="grid grid-cols-3 gap-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                {formState.productMediaUrls.map(({ url }, index) => (
                  <motion.div
                    key={index}
                    className="relative group"
                    whileHover={{ scale: 1.05 }}
                  >
                    <img
                      src={url}
                      alt="Product"
                      className="w-full h-32 object-cover rounded-md"
                    />
                    <motion.button
                      onClick={(e) => handleDeleteImage(index)}
                      className="absolute top-2 left-2     rounded-full opacity-0 group-hover:opacity-100 transition-opacity p-1.5 rounded-full  hover:text-red-500 hover:bg-red-100 transition-colors duration-200"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      type="button"
                      aria-label="Delete item"
                    >
                      <Trash2 size={20} />
                    </motion.button>
                  </motion.div>
                ))}
              </motion.div>
            )}

            <motion.div
              style={{ width: '100%' }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                endIcon={
                  <SendHorizontal
                    style={{
                      width: '30px',
                      height: '30px',
                      transform: 'rotate(180deg)',
                    }}
                  />
                }
                style={{ height: '60px', width: '100%', fontSize: '24px' }}
              >
                הוסף תבנית
              </Button>
            </motion.div>
          </form>
        </motion.div>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default TemplateMaker;
