import React, { useState, useEffect, useCallback } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  PlusCircle,
  Trash2,
  Film,
  Image,
  FileText,
  Save,
  Hourglass,
  ArrowDownUp,
  IdCard,
  FileType2,
  ArrowRight,
} from "lucide-react";
import Modal from "../Modal/Modal";
import FullPageActionCards from "../FullPageActionCards/FullPageActionCards";
import DeviceSettings from "../Device/DeviceSettings/DeviceSettings";
import PlaylistAddItemForm from "../PlaylistAddItemForm/PlaylistAddItemForm";
import MediaFilterComponent from "../MediaFilterComponent/MediaFilterComponent";
import mainApi from "../../api/MainApi";
import "./ItemsModal.css";

// Constants
const STAGES = {
  LIST: 0,
  ADD_ITEM_OPTIONS: 2,
  CREATE_NEW: 3,
  SELECT_EXISTING: 4,
  ADD_FORM: 5,
};

const ITEM_TYPES = {
  VIDEO: "video",
  IMAGE: "image",
};

// Components
const ItemTypeIcon = ({ itemType }) => {
  const icons = {
    [ITEM_TYPES.VIDEO]: Film,
    [ITEM_TYPES.IMAGE]: Image,
    default: FileText,
  };

  const IconComponent = icons[itemType] || icons.default;
  return <IconComponent className={`icon icon-${itemType || "file"}`} />;
};

const EmptyState = () => (
  <div className="flex items-center justify-center h-64 bg-gray-100 rounded-lg shadow-inner empty-state">
    <div className="text-center">
      <h2 className="text-2xl font-bold text-gray-700 mb-2">
        אין פריטים ברשימה
      </h2>
      <p className="text-gray-500">הוסף פריטים חדשים כדי להתחיל</p>
    </div>
  </div>
);

const ItemDetails = ({ item, index }) => (
  <div className="item-details">
    <div className="item-side1">
      <span className="item-info--text item-type">
        <FileType2 className="item-info--icon" />
        {item?.itemType}
      </span>
      <span className="item-info--text item-id">
        <IdCard className="item-info--icon" />
        {item?._id.slice(-6)}
      </span>
    </div>
    <div className="item-side2">
      <span className="item-info--text item-order">
        <ArrowDownUp className="item-info--icon" />
        {index + 1}
      </span>
      <span className="item-info--text item-duration">
        <Hourglass className="item-info--icon" />
        {item.duration} שניות
      </span>
    </div>
  </div>
);

const ItemsList = ({ items, onDragEnd, onDeleteItem }) => (
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="items">
      {(provided) => (
        <ul
          {...provided.droppableProps}
          ref={provided.innerRef}
          className="item-list"
        >
          {items.length === 0 ? (
            <EmptyState />
          ) : (
            items.map((item, index) => (
              <Draggable key={item._id} draggableId={item._id} index={index}>
                {(provided) => (
                  <li
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="item"
                    id={item._id}
                  >
                    <div className="item-info">
                      <ItemTypeIcon itemType={item.itemType} />
                      <h3 className="item-name">{item.name}</h3>
                      <ItemDetails item={item} index={index} />
                    </div>
                    <div className="item-actions">
                      <Trash2
                        className="btn-icon"
                        onClick={() => onDeleteItem(item._id)}
                      />
                    </div>
                  </li>
                )}
              </Draggable>
            ))
          )}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  </DragDropContext>
);

// Custom Hooks
const useStageNavigation = (initialStage = STAGES.LIST) => {
  const [currentStage, setCurrentStage] = useState(initialStage);
  const [history, setHistory] = useState([initialStage]);

  const navigateTo = useCallback((stage) => {
    setCurrentStage(stage);
    setHistory((prev) => [...prev, stage]);
  }, []);

  const goBack = useCallback(() => {
    if (history.length > 1) {
      const newHistory = history.slice(0, -1);
      setHistory(newHistory);
      setCurrentStage(newHistory[newHistory.length - 1]);
    }
  }, [history]);

  return { currentStage, navigateTo, goBack };
};

const useResourceData = () => {
  const [data, setData] = useState([]);

  const fetchAllResources = useCallback(async () => {
    try {
      const response = await mainApi.getAllResources();
      setData(response);
    } catch (error) {
      console.error("Error fetching resources:", error);
      throw error.message;
    }
  }, []);

  return { data, fetchAllResources };
};

// Main Component
const ItemsModal = ({
  isOpen,
  onClose,
  items,
  onDeleteItem,
  onAddItem,
  onSaveOrder,
  onUploadSubmit,
  onPayngoItemFetch,
  playlistId,
}) => {
  const [localItems, setLocalItems] = useState(items);
  const [isOrderChanged, setIsOrderChanged] = useState(false);
  const [selectedResourceItem, setSelectedResourceItem] = useState(null);
  const { currentStage, navigateTo, goBack } = useStageNavigation();
  const { data, fetchAllResources } = useResourceData();
  useEffect(() => {
    const fetchData = async () => {
      await fetchAllResources();
    };

    fetchData();
  }, [currentStage, fetchAllResources]);

  useEffect(() => {
    setLocalItems(items);
  }, [items]);

  const handleDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;

      const newItems = Array.from(localItems);
      const [reorderedItem] = newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, reorderedItem);

      const updatedItems = newItems.map((item, index) => ({
        ...item,
        order: index,
      }));

      setLocalItems(updatedItems);
      setIsOrderChanged(true);
    },
    [localItems]
  );

  const handleSaveOrder = useCallback(() => {
    onSaveOrder(localItems);
    setIsOrderChanged(false);
  }, [localItems, onSaveOrder]);

  const handleDeleteItem = useCallback(
    (itemId) => {
      onDeleteItem(playlistId, itemId);
    },
    [onDeleteItem, playlistId]
  );

  const handleAddItem = useCallback(
    async (data) => {
      await onAddItem(data);
      navigateTo(STAGES.LIST);
    },
    [onAddItem, navigateTo]
  );

  const handleUploadSubmit = useCallback(
    async (data, setStats) => {
      await onUploadSubmit(data, setStats);
      navigateTo(STAGES.SELECT_EXISTING);
    },
    [onUploadSubmit, navigateTo]
  );

  const handleClose = useCallback(() => {
    navigateTo(STAGES.LIST);
    onClose();
  }, [navigateTo, onClose]);

  const renderMainContent = () => {
    switch (currentStage) {
      case STAGES.LIST:
        return (
          <>
            <div className="items-actions">
              <button
                onClick={() => navigateTo(STAGES.ADD_ITEM_OPTIONS)}
                className="btn btn-add"
              >
                <PlusCircle className="btn-icon" aria-hidden="true" />
                הוסף פריט
              </button>
              {isOrderChanged && (
                <button onClick={handleSaveOrder} className="btn btn-save">
                  <Save className="btn-icon" aria-hidden="true" />
                  שמור סדר
                </button>
              )}
            </div>
            <ItemsList
              items={localItems}
              onDragEnd={handleDragEnd}
              onDeleteItem={handleDeleteItem}
            />
          </>
        );
      case STAGES.ADD_ITEM_OPTIONS:
        return (
          <FullPageActionCards
            onClickAddExisting={async () => {
              await fetchAllResources();
              navigateTo(STAGES.SELECT_EXISTING);
            }}
            onClickCreateNew={async () => {
              navigateTo(STAGES.CREATE_NEW);
            }}
          />
        );
      case STAGES.CREATE_NEW:
        return (
          <DeviceSettings
            onSubmit={handleUploadSubmit}
            onPayngoItemFetch={onPayngoItemFetch}
          />
        );
      case STAGES.SELECT_EXISTING:
        return (
          <div className="reactItems">
            <MediaFilterComponent
              mediaItems={data}
              onSelect={(item) => {
                setSelectedResourceItem(item);
                navigateTo(STAGES.ADD_FORM);
              }}
            />
          </div>
        );
      case STAGES.ADD_FORM:
        return (
          <div className="item-card-with-form">
            <PlaylistAddItemForm
              onSubmit={handleAddItem}
              item={selectedResourceItem}
              type={
                selectedResourceItem.hasOwnProperty("templateId")
                  ? "Template"
                  : "Asset"
              }
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title="ניהול פריטים"
      zIndex={100}
    >
      {currentStage !== STAGES.LIST && (
        <button className="icon-back-button" onClick={goBack}>
          <ArrowRight className="icon-back" />
          חזור
        </button>
      )}
      {renderMainContent()}
    </Modal>
  );
};

export default ItemsModal;
