import React, { useState, useEffect, useRef } from "react";

import { AlertTriangle } from "lucide-react";
import "./ConfirmDialog.css";

const ConfirmDialog = ({ onClose, title, text, onAccept, isVisible }) => {
  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm flex items-center justify-center z-50 confirm-dialog  animate-fadeIn ${
        !isVisible && "hide"
      }`}
      onClick={onClose}
    >
      {/* Dialog Content */}
      <div
        className="bg-white rounded-2xl shadow-2xl max-w-md w-full mx-4 overflow-hidden animate-scaleIn"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Dialog Header */}
        <div className="px-8 pt-8 pb-4">
          <h3 className="text-xl font-bold text-gray-900 mb-1">{title}</h3>
          <p className="text-gray-500">{text}</p>
        </div>

        {/* Dialog Footer */}
        <div className="px-8 py-6 flex justify-end space-x-3 space-x-reverse">
          <button
            className="px-5 py-2.5 bg-white border border-gray-200 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors duration-150 focus:outline-none focus:ring-2 focus:ring-gray-200 font-medium"
            onClick={onClose}
          >
            ביטול
          </button>
          <button
            className="px-5 py-2.5 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors duration-150 focus:outline-none focus:ring-2 focus:ring-red-300 font-medium"
            onClick={onAccept}
          >
            אישור
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;
