import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import "./PopupWindow.css";

const PopupWindow = ({ isOpen, onClose, children, classNameContent }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <div className="popup-overlay" onClick={onClose}>
          <motion.div
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "100%", opacity: 0 }}
            transition={{ type: "tween", duration: 0.3, ease: "easeOut" }}
            className={`popup-content ${classNameContent}`}
            onClick={(e) => e.stopPropagation()} // מונע סגירה בלחיצה על הפופאפ עצמו
          >
            <CloseIcon onClick={onClose} className="popup-overlay-close-button" />
            {children}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default PopupWindow;
