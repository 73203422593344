import React, { useState, useEffect, Suspense, lazy, useContext } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from 'react-router-dom';
import './App.css';
import Cookies from 'js-cookie';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import showToast from '../../utils/toast';
import useFetchPlaylist from '../../utils/hooks/useFetchPlaylist';
import { AuthStatusContext } from '../../contexts/AuthStatus';

// Components
import PlaylistPlayer from '../PlaylistPlayer/PlaylistPlayer';
import PlaylistBrowsePage from '../../pages/PlaylistBrowsePage/PlaylistBrowsePage';
import PlaylistCreatorPage from '../../pages/PlaylistCreatorPage/PlaylistCreatorPage';
import TestPage from '../../pages/TestPage/TestPage';
import PagePreloader from '../PagePreloader/PagePreloader';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import PopupWindow from '../PopupWindow/PopupWindow';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddNewPlaylist from '../../pages/AddNewPlaylist/AddNewPlaylist';
// Utilities and APIs
import mainApi from '../../api/MainApi';
import getCurrentLocation from '../../utils/getCurrentLocation';
import { handleMainError } from '../../utils/errors/errorHandlers';

// Contexts

import { AuthProvider } from '../../contexts/AuthProvider';
import { AuthStatusProvider } from '../../contexts/AuthProvider';

// Lazy-loaded components
const PlaylistPage = lazy(() =>
  import('../../pages/PlaylistPage/PlaylistPage')
);
const AdminDashboard = lazy(() =>
  import('../../pages/AdminDashboard/AdminDashboard')
);
const DevicePage = lazy(() => import('../../pages/DevicePage/DevicePage'));
const DeviceRegisterForm = lazy(() =>
  import('../Device/DeviceRegisterForm/DeviceRegisterForm')
);
const DeviceSettings = lazy(() =>
  import('../Device/DeviceSettings/DeviceSettings')
);
const ResourceCatalogPage = lazy(() =>
  import('../../pages/ResourceCatalogPage/ResourceCatalogPage')
);
const AssetList = lazy(() => import('../AssetList/AssetList'));
const AssetDetail = lazy(() => import('../AssetDetail/AssetDetail'));
const TemplateList = lazy(() => import('../TemplateList/TemplateList'));
const TemplateDetail = lazy(() => import('../TemplateDetail/TemplateDetail'));
const ContentRenderer = lazy(() =>
  import('../PlaylistRenderer/PlaylistRenderer')
);
const LayoutComponent = lazy(() => import('../../layout/LayoutComponent'));
const Login = lazy(() => import('../SignInPopup/SignInPopup'));

// Create a client
const queryClient = new QueryClient();

// Custom error fallback component
const ErrorFallback = ({ error, resetErrorBoundary }) => (
  <div role="alert">
    <p>Something went wrong:</p>
    <pre>{error.message}</pre>
    <button onClick={resetErrorBoundary}>Try again</button>
  </div>
);

// Prefetch component
const Prefetch = ({ children }) => {
  useEffect(() => {
    const routes = ['/c/devices', '/c/resources'];
    routes.forEach((route) => {
      const link = document.createElement('link');
      link.rel = 'prefetch';
      link.href = route;
      document.head.appendChild(link);
    });
  }, []);

  return children;
};

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useContext(AuthStatusContext);

  const { devicePlaylist, fetchPlaylistByDeviceId } = useFetchPlaylist();
  const [playlistsData, setPlaylistsData] = useState([]);
  // State declarations
  const [uploadState, setUploadState] = useState({
    isLoading: false,
    progress: 0,
    isComplete: false,
  });
  const [displayDeviceId, setDisplayDeviceId] = useState(null);
  const [displayDeviceIsVerified, setDisplayDeviceIsVerified] = useState(false);

  const [isPopupDeviceContentOpen, setIsPopupWindowDeviceContentOpen] =
    useState(false);
  const [deviceContentPreview, setDeviceContentPreview] = useState(null);
  const [isDevicePlaylistModalOpen, setIsDevicePlaylistModalOpen] =
    useState(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [deviceList, setDeviceList] = useState([]);
  const [devicePendingList, setDevicePendingList] = useState([]);
  const [deviceVerifiedList, setDeviceVerifiedList] = useState([]);
  const [deviceSavedList, setDeviceSavedList] = useState([]);
  // Effect for device authentication and content fetching
  useEffect(() => {
    if (displayDeviceId && !displayDeviceIsVerified) {
      const intervalId = setInterval(handleCheckDeviceAuthentication, 3000);
      return () => clearInterval(intervalId);
    } else if (displayDeviceIsVerified) {
      const intervalId = setInterval(fetchPlaylistByDeviceId, 2000);
      return () => clearInterval(intervalId);
    }
  }, [displayDeviceId, displayDeviceIsVerified]);

  // Handler functions
  const handleFileUpload = async (file, setStats) => {
    if (!file) return;
    try {
      const response = await mainApi.uploadFile(file, (progress) => {
        console.log('Progress:', progress);

        setStats({
          isLoading: true,
          progress: progress,
          isComplete: false,
        });
      });
      if (response.ok !== true) {
        throw new Error('Failed to upload file');
      }

      showToast('הקובץ עלה בהצלחה!');
      return true;
    } catch (error) {
      handleMainError(error, true);
    } finally {
      setStats({ isLoading: false, progress: 0, isComplete: false });
    }
  };

  const createNewResource = async (formData, setStats) => {
    if (uploadState.isLoading) return;

    const endpointMap = {
      video: 'url',
      image: 'url',
      template: 'template',
    };

    try {
      if (formData.type === 'file') {
        return handleFileUpload(formData.file, setStats);
      }

      const endpoint = endpointMap[formData.type];
      if (!endpoint) {
        throw new Error('Invalid form data type');
      }

      const response = await mainApi.createAssetsResource(endpoint, formData);
      const toastMessage =
        formData.type === 'template' ? 'התבנית נוצרה' : 'הקישור נוצר';
      showToast(toastMessage);

      return response;
    } catch (error) {
      handleMainError(error, true);
    }
  };

  const verifyNewDevice = async (id, formData) => {
    try {
      const location = await getCurrentLocation().catch(() => ({}));
      formData.location = location;
      const response = await mainApi.verifyAndAddDevice(id, formData);
      if (response.status !== 'ok') {
        throw new Error('Failed to add device');
      }
      showToast('המכשיר נוסף בהצלחה');
      fetchAndCategorizeDevices();
      return true;
    } catch (error) {
      handleMainError(error, true);
      return false;
    }
  };

  const fetchAndCategorizeDevices = async () => {
    try {
      const devices = await mainApi.getAllDevices();
      setDeviceList(devices);
      return true;
    } catch (error) {
      console.log('Error:', error);
      setDeviceList([]);
      const showError = error.status === 404;
      handleMainError(error, !showError);
      return false;
    }
  };
  useEffect(() => {
    if (!isAuthenticated) return;
    fetchAndCategorizeDevices();
  }, [isAuthenticated]);
  // check this

  const handleFetchDevice2 = async (id) => {
    try {
      console.warn('unknown function');

      /*       const response = await mainApi.fetchDevice2();
      setDevicePlaylist(response); */
    } catch (error) {
      handleMainError(error, true);
    }
  };

  const fetchPayngoItemInfo = async (barcode) => {
    try {
      const response = await mainApi.getPayngoItemInfo(barcode);
      if (response.status === 404) {
        return new Error('NotFound');
      }
      return response;
    } catch (error) {
      handleMainError(error, true);
    }
  };

  const handleCheckDeviceAuthentication = async () => {
    try {
      const id = Cookies.get('device_id');
      const response = await mainApi.checkDeviceAuthentication(id);
      setDisplayDeviceIsVerified(response.isAuthorized);
    } catch (error) {
      if (error.status === 404) {
        Cookies.remove('device_id');
        window.location.reload();
      }
      console.warn('NotRealError:', error);
      return false;
    }
  };

  const handleRegisterDevice = async () => {
    try {
      const deviceId = await mainApi.registerDevice();
      Cookies.set('device_id', deviceId.device_id, { expires: 365 });
      setDisplayDeviceId(deviceId.device_id);
    } catch (error) {
      handleMainError(error, true);
    }
  };

  const handleLoadActiveDisplayPage = async () => {
    try {
      const deviceId = Cookies.get('device_id');
      setDisplayDeviceId(deviceId);
      if (deviceId) {
        handleCheckDeviceAuthentication();
      } else {
        handleRegisterDevice();
      }
    } catch (error) {
      handleMainError(error, true);
    }
  };

  const handleDeleteDevice = async (id) => {
    try {
      await mainApi.deleteDevice(id);
      showToast('המכשיר נמחק בהצלחה');
      fetchAndCategorizeDevices();
      return true;
    } catch (error) {
      handleMainError(error, true);
    }
  };

  const handleBookmarkDevice = async (id) => {
    try {
      const response = await mainApi.bookmarkDevice(id);
      const toastMessage = response.isBookmarked
        ? 'הפריט נשמר במועדפים שלך'
        : 'הפריט הוסר מהמועדפים שלך';

      /* setDeviceList((prev) => {
        return prev?.map((device) => {
          if (device._id === id) {
            return { ...device, isBookmarked: response.isBookmarked };
          }
          return device;
        });
      }); */

      fetchAndCategorizeDevices();
      showToast(toastMessage);
    } catch (error) {
      handleMainError(error, true);
    }
  };

  useEffect(() => {
    /*  */
    const categorizedDevices = deviceList.reduce(
      (acc, device) => {
        if (device.isBookmarked) acc.saved.push(device);
        if (device.isAuthorized) acc.verified.push(device);
        else acc.pending.push(device);
        return acc;
      },
      { saved: [], verified: [], pending: [] }
    );

    setDevicePendingList(categorizedDevices?.pending);
    setDeviceVerifiedList(categorizedDevices?.verified);
    setDeviceSavedList(categorizedDevices?.saved);
  }, [deviceList]);
  const handleShowStream = async (content) => {
    try {
      const response = await mainApi.getPlaylistByDeviceId(content._id);
      setDeviceContentPreview(response);
      setIsPopupWindowDeviceContentOpen(true);
    } catch (error) {
      handleMainError(error, true);
    }
  };

  const handleVerifyDevice = async (id) => {
    try {
      navigate(`/c/devices/add-device/${id}`);
    } catch (error) {
      handleMainError(error, true);
    }
  };

  const handleAssetDelete = async (id) => {
    try {
      await mainApi.deleteAsset(id);

      showToast('הנכס נמחק בהצלחה');
      return true;
    } catch (error) {
      handleMainError(error, true);
      return false;
    }
  };

  const handleTemplateDelete = async (id) => {
    try {
      await mainApi.deleteTemplate(id);
      showToast('התבנית נמחקה בהצלחה');
      return true;
    } catch (error) {
      handleMainError(error, true);
      return false;
    }
  };

  const handleAssignPlaylistToDevice = async (deviceId, playlistId) => {
    console.log('deviceId', deviceId);

    try {
      const tes = await mainApi.assignPlaylistToDevice(deviceId, playlistId);
      console.log('tes', tes);

      showToast('הפלייליסט הוקצה למכשיר');
      return true;
    } catch (error) {
      handleMainError(error, true);
      return false;
    }
  };
  const handleSubmitNewPlaylist = async (playlistData) => {
    console.log('playlistData', playlistData);

    try {
      const newPlaylist = await mainApi.createPlaylist(playlistData);
      /*       setIsPlaylistAddModalOpen(false); */

      showToast('הפלייליסט נוצר בהצלחה', 'success');
    } catch (error) {
      handleMainError(error, true);
    }
  };

  const handleSelectDevicePlaylist = async (deviceId) => {
    setIsDevicePlaylistModalOpen(true);
    setSelectedDeviceId(deviceId);
    console.log('handleSelectDevicePlaylist', deviceId);
  };

  const [isDeviceAddPlaylistModalOpen, setIsDeviceAddPlaylistModalOpen] =
    useState(false);
  const handleAssignPlaylistToDeviceSelf = async (playlistId) => {
    console.log('handleAssignPlaylistToDeviceSelf', playlistId);
  };

  const handleCreatePlaylist = async (playlistData) => {
    try {
      setIsDevicePlaylistModalOpen(false);
      const newPlaylist = await mainApi.createPlaylist(playlistData);
      const getAllPlaylists = await mainApi.getAllPlaylist();
      setPlaylistsData(getAllPlaylists);
      
      setIsDeviceAddPlaylistModalOpen(false);
      setIsDevicePlaylistModalOpen(true);
      showToast('הפלייליסט נוצר בהצלחה', 'success');
    } catch (error) {
      handleMainError(error, true);
    }
  };

  useEffect(() => {
    if (location.pathname === '/c/devices/pending') {
      fetchAndCategorizeDevices();
    }
  }, [location]);
  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer />
      <PopupWindow
        isOpen={isPopupDeviceContentOpen}
        onClose={() => {
          setDeviceContentPreview({});
          setIsPopupWindowDeviceContentOpen(false);
        }}
      >
        <ContentRenderer
          className="DisplayDeviceComponentPopup"
          VideoPlayerClassName={'video-player_type_popup'}
          playlist={deviceContentPreview}
        />
      </PopupWindow>
      <PopupWindow
        isOpen={isDevicePlaylistModalOpen}
        classNameContent="popup-content-playlist-web"
        onClose={() => {
          setIsDevicePlaylistModalOpen(false);
        }}
      >
        <PlaylistBrowsePage
          title={'הקצאת פלייליסט'}
          isSelfService={true}
          selectedDeviceId={selectedDeviceId}
          onAddNewPlaylist={() => {
            setIsDeviceAddPlaylistModalOpen(true);
          }}
          playlists={playlistsData}
          onAssignPlaylistToDevice={handleAssignPlaylistToDevice}
          devices={deviceList}
          onPayngoItemFetch={fetchPayngoItemInfo}
          onUploadSubmit={createNewResource}
        />
      </PopupWindow>
      <PopupWindow
        isOpen={isDeviceAddPlaylistModalOpen}
        onClose={() => {
          setIsDeviceAddPlaylistModalOpen(false);
        }}
      >
        <AddNewPlaylist
          classNameContent="popup-content-add-new-playlist "
          onSubmit={handleCreatePlaylist}
        />
      </PopupWindow>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Suspense fallback={<PagePreloader />}>
          <Prefetch>
            <Routes location={location}>
              <Route path="/" element={<Navigate replace to="/display" />} />

              <Route
                path="/display"
                element={
                  <PlaylistPage
                    deviceId={displayDeviceId}
                    displayDeviceIsVerified={displayDeviceIsVerified}
                    onRegisterDevice={handleRegisterDevice}
                    onLoad={handleLoadActiveDisplayPage}
                    onExistedDevice={setDisplayDeviceId}
                    onGetDeviceContent={fetchPlaylistByDeviceId}
                    onCheckDeviceAuthentication={
                      handleCheckDeviceAuthentication
                    }
                    playlist={devicePlaylist}
                  />
                }
              />

              <Route
                element={
                  <AuthProvider>
                    <ProtectedRoute permissions={['admin']} />
                  </AuthProvider>
                }
              >
                <Route element={<LayoutComponent />}>
                  <Route path="/c" element={<AdminDashboard />} />
                  <Route path="/c/devices">
                    <Route
                      index
                      element={
                        <DevicePage
                          fetchDevices={fetchAndCategorizeDevices}
                          onSelectDevicePlaylist={handleSelectDevicePlaylist}
                          deviceList={deviceVerifiedList}
                          onDeleteDevice={handleDeleteDevice}
                          deviceType="verified"
                          onBookmarkDevice={handleBookmarkDevice}
                          onShowStreamClick={handleShowStream}
                        />
                      }
                    />
                    <Route
                      path="pending"
                      element={
                        <DevicePage
                          fetchDevices={fetchAndCategorizeDevices}
                          deviceList={devicePendingList}
                          onDeleteDevice={handleDeleteDevice}
                          deviceType="pending"
                          onVerifyDevice={handleVerifyDevice}
                        />
                      }
                    />
                    <Route
                      path="add-device/:id"
                      element={
                        <DeviceRegisterForm onSubmit={verifyNewDevice} />
                      }
                    />
                    <Route
                      path="verified/:id"
                      element={
                        <DeviceSettings
                          onSubmit={createNewResource}
                          onFetchDevice2={handleFetchDevice2}
                          onPayngoItemFetch={fetchPayngoItemInfo}
                        />
                      }
                    />
                    <Route
                      path="saved"
                      element={
                        <DevicePage
                          fetchDevices={fetchAndCategorizeDevices}
                          onSelectDevicePlaylist={handleSelectDevicePlaylist}
                          onDeleteDevice={handleDeleteDevice}
                          deviceList={deviceSavedList}
                          onBookmarkDevice={handleBookmarkDevice}
                          deviceType="saved"
                          onShowStreamClick={handleShowStream}
                        />
                      }
                    />
                    <Route
                      path=":id"
                      element={
                        <DevicePage
                          fetchDevices={fetchAndCategorizeDevices}
                          onSelectDevicePlaylist={handleSelectDevicePlaylist}
                          deviceList={deviceVerifiedList}
                          onDeleteDevice={handleDeleteDevice}
                          deviceType="verified"
                          onBookmarkDevice={handleBookmarkDevice}
                          onShowStreamClick={handleShowStream}
                        />
                      }
                    />
                  </Route>

                  <Route path="/c/resources">
                    <Route
                      index
                      element={
                        <ResourceCatalogPage
                          onAssetDelete={handleAssetDelete}
                          onTemplateDelete={handleTemplateDelete}
                        />
                      }
                    />
                    <Route
                      path="search"
                      element={
                        <ResourceCatalogPage
                          onAssetDelete={handleAssetDelete}
                          onTemplateDelete={handleTemplateDelete}
                        />
                      }
                    />
                    <Route
                      path="add"
                      element={
                        <DeviceSettings
                          onSubmit={createNewResource}
                          onFetchDevice2={handleFetchDevice2}
                          onPayngoItemFetch={fetchPayngoItemInfo}
                        />
                      }
                    />
                    <Route path="assets">
                      <Route index element={<AssetList />} />
                      <Route path=":id" element={<AssetDetail />} />
                    </Route>
                    <Route path="templates">
                      <Route index element={<TemplateList />} />
                      <Route path=":id" element={<TemplateDetail />} />
                    </Route>
                  </Route>
                  <Route path="/c/playlists">
                    <Route
                      index
                      element={
                        <PlaylistBrowsePage
                          onAssignPlaylistToDevice={
                            handleAssignPlaylistToDevice
                          }
                          devices={deviceList}
                          onPayngoItemFetch={fetchPayngoItemInfo}
                          onUploadSubmit={createNewResource}
                        />
                      }
                    />
                  </Route>
                  <Route path="/c/playlists/new">
                    <Route
                      index
                      element={
                        <AddNewPlaylist onSubmit={handleCreatePlaylist} />
                      }
                    />
                  </Route>
                </Route>
              </Route>

              <Route
                path="/invalid-id"
                element={<div>Invalid Device ID</div>}
              />

              <Route
                path="/login"
                element={
                  <LayoutComponent>
                    <Login />
                  </LayoutComponent>
                }
              />
              <Route path="*" element={<h1>404 - NOT FOUND</h1>} />
            </Routes>
          </Prefetch>
        </Suspense>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
