import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";
import mainApi from "../api/MainApi";
 
const FetchContext = createContext();

/**
 * Provides global state and functions for managing resources and devices.
 * This context allows fetching, deleting, and managing data globally across the application.
 *
 * @param {React.ReactNode} children - The components that will have access to this context.
 */
export const FetchProvider = ({ children }) => {
  const [data, setData] = useState({
    resources: null,
    devices: null,
  });

  /**
   * Fetches all available resources from the API and updates the global state.
   *
   * @throws {Error} If the API request fails.
   *
   * @example
   * fetchAllResources();
   */
  const fetchAllResources = useCallback(async () => {
    try {
      const response = await mainApi.getAllResources();
      setData((prevData) => ({ ...prevData, resources: response }));
    } catch (error) {
      console.error("Error fetching resources:", error);
  
      throw error.message;
    }
  }, []);

  /**
   * Fetches all available devices from the API and updates the global state.
   *
   * @throws {Error} If the API request fails.
   *
   * @example
   * fetchAllDevices();
   */
  const fetchAllDevices = useCallback(async () => {
    try {
      
      const response = await mainApi.getAllDevices();
      setData((prevData) => ({ ...prevData, devices: response }));
    } catch (error) {
      console.error("Error fetching devices:", error);
      throw error;
    }
  }, []);

  /**
   * Deletes a resource by its ID and type.
   *
   * @param {string} id - The unique identifier of the resource to delete.
   * @param {"template" | "asset"} type - The type of the resource (must be either "template" or "asset").
   *
   * @throws {Error} If the type is unknown or an API request fails.
   *
   * @example
   * // Delete a resource
   * deleteResource("12345", "template");
   * deleteResource("67890", "asset");
   */
  const deleteResource = useCallback(async (id, type) => {
    try {
      if (type === "template") {
        await mainApi.deleteTemplate(id);
      } else if (type === "asset") {
        await mainApi.deleteAsset(id);
      } else {
        throw new Error("Unknown type");
      }

      // Update the global state after deletion
      setData((prevData) => ({
        ...prevData,
        resources: prevData.resources
          ? prevData.resources.filter((item) => item._id !== id)
          : [],
      }));
    } catch (error) {
      console.error("Error deleting resource:", error);
      throw error;
    }
  }, []);

  /**
   * The context value containing the global data state and functions for managing resources and devices.
   */
  const contextValue = useMemo(
    () => ({
      data,
      fetchAllResources,
      fetchAllDevices,
      deleteResource,
    }),
    [data, fetchAllResources, fetchAllDevices, deleteResource]
  );

  return (
    <FetchContext.Provider value={contextValue}>
      {children}
    </FetchContext.Provider>
  );
};

/**
 * A custom hook to access the FetchContext.
 *
 * @returns {{
 *   data: { resources: Array | null, devices: Array | null },
 *   fetchAllResources: function,
 *   fetchAllDevices: function,
 *   deleteResource: function
 * }}
 *
 * @example
 * const { data, fetchAllResources, deleteResource } = useFetchContext();
 */
export const useFetchContext = () => useContext(FetchContext);
