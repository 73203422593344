import React, { useState, useEffect } from "react";
import "./MediaCarousel.css";
const MediaCarousel = ({ item, timer }) => {
  const { productMediaUrls } = item;
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % productMediaUrls.length);
    }, timer);

    return () => clearInterval(interval);
  }, [productMediaUrls.length]);

  const currentItem = productMediaUrls[currentIndex];

  const renderIndicators = () => {
    return (
      <div className=" media-carousel-indicators">
        {productMediaUrls.map((_, index) => (
          <button
            key={index}
            className={`media-carousel-indicators-button ${
              index === currentIndex ? "bg-blue-500" : "bg-gray-300"
            }`}
            onClick={() => setCurrentIndex(index)}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="template relative media-carousel">
      <div className="media-container relative w-full h-64 overflow-hidden">
        {productMediaUrls.map((mediaItem, index) => (
          <div
            key={mediaItem._id}
            className={`absolute inset-0 transition-opacity duration-500 ${
              index === currentIndex ? "opacity-100" : "opacity-0"
            }`}
          >
            {mediaItem.urlType === "video" ? (
              <video
                src={mediaItem.url}
                autoPlay
                loop
                muted
                className="videoTag w-full h-full object-contain"
              />
            ) : (
              <img
                src={mediaItem.url}
                alt={`תמונה ${index + 1}`}
                className="imageTag w-full h-full object-contain"
              />
            )}
          </div>
        ))}
      </div>

      {renderIndicators()}

      {item && <p className="text mt-2 media-carousel-text">{item.text}</p>}
      {item && <PriceSwitcher fileDetails={item} />}
    </div>
  );
};

// כדי לאפשר את הרכיב לפעול גם בלי העברת props
const PriceSwitcher = ({ fileDetails }) => {
  // במצב אמיתי כאן יהיה מימוש של מחירים
  return fileDetails?.price ? (
    <div className="price-container mt-2">
      <span className="price">{fileDetails.price}</span>
    </div>
  ) : null;
};

export default MediaCarousel;
